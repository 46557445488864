import { Title } from '@bpm-web-app/components';
import { CreateBrowse } from '@bpm-web-app/components/pages';

export default function Index({ title }: { title: string }) {
    return (
        <>
            <Title title={title} platform="create" />
            <CreateBrowse />
        </>
    );
}

export async function getStaticProps() {
    return {
        props: {
            title: 'Home'
        }
    };
}
